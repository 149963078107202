export const paymentMethods = [
  {
    id: 1,
    title: `Оплата післяплатою`,
    description: `В цьому випадку доставка здійснюється тільки через Нову Пошту. 
    Ціна доставки збільшується у зв'язку з оплатою за зворотню доставку грошей на 
    2% вартості купівлі, плюс фіксована сума за зворотнє пересилання (тарифи Нової Пошти). 
    У разі замовлення товару післяплатою, обов'язково вноситься передоплата за доставку товарів в обидва кінця.
     Решта вартості оплачується при отриманні товару + сплачуються послуги Нової Пошти за доставку.`,
  },
  {
    id: 2,
    title: `Грошовий переказ`,
    description: `Для того, щоб здійснити оплату замовлення через систему електронного 
    переказу грошей, Вам необхідно зв'язатися з менеджером Інтернет-магазину і уточнити всю потрібну інформацію`,
  },
  {
    id: 3,
    title: `Безготівковий розрахунок`,
    description: `Після отримання вашого замовлення, з вами зв'яжиться наш 
    менеджер, для уточнення необхідними документами (детальніше – в інструкції для Юрлиць), 
    після чого відправе вам рахунок на оплату - електронною поштою або через месенджери. 
    Оплатити виставлений рахунок можна через будь-який банк на зазначені реквізити.
    При цьому різновиді розрахунку, відвантаження товару здійснюється тільки після 100% оплати рахунку`,
  },
  {
    id: 4,
    title: `Оплата по факту`,
    description: `Оплата здійснюється співробітнику компанії, по факту поставки 
    товарів на указану вами адресу, за попереднім узгодженням з менеджером, 
    та при попереднім здійсненні оплати за вартість доставки в обидва кінці.`,
  },
  {
    id: 5,
    title: `Поповнення банківської карти`,
    description: `Необхідні для здійснення оплати номер банківської картки та ПІБ власника наш 
    менеджер надішле в sms-повідомленні або через месенджери. Сума зробленого Вами грошового 
    переказу може зараховуватися на картку до трьох банківських днів, проте, як правило, це 
    відбувається протягом однієї години з моменту переведення. Зверніть увагу, що за свої послуги 
    банк може взяти додаткову комісію`,
  },
];

export const deliveryMethods = [
  {
    id: 1,
    title: `Вантажним транспортом, що відповідає об'єму та особливостям вашого замовлення, на вказану вами адресу `,
  },
  {
    id: 2,
    title: `"Новою поштою" - для негабаритних замовлень - у відділення або на вказану вами адресу`,
  },
];

export const paymentMethodsDescription = [
  {
    id: 1,
    title: `Оплата післяплатою`,
    description: `В цьому випадку доставка здійснюється тільки через Нову Пошту. Ціна доставки збільшується у зв'язку з 
    оплатою за зворотню доставку грошей на 2% вартості купівлі, плюс фіксована сума за зворотнє пересилання 
    (тарифи Нової Пошти). У разі замовлення товару післяплатою, обов'язково вноситься передоплата за доставку товарів 
    в обидва кінця. Решта вартості оплачується при отриманні товару + сплачуються послуги Нової Пошти за доставку.`,
  },
  {
    id: 2,
    title: `Поповнення банківської карти`,
    description: `Необхідні для здійснення оплати номер банківської картки та ПІБ власника наш менеджер надішле в 
    sms-повідомленні або через месенджери. Сума зробленого Вами грошового переказу може зараховуватися на картку до 
    трьох банківських днів, проте, як правило, це відбувається протягом однієї години з моменту переведення. Зверніть 
    увагу, що за свої послуги банк може взяти додаткову комісію`,
  },
  {
    id: 3,
    title: `Безготівковий розрахунок`,
    description: `Після отримання вашого замовлення з вами зв'яжеться наш менеджер для уточнення необхідних 
    документів (детальніше – в інструкції для юридичних осіб), після чого відправить вам рахунок на оплату - електронною поштою
     або через месенджери. Оплатити виставлений рахунок можна через будь-який банк на зазначені реквізити.
      При цьому різновиді розрахунку, відвантаження товару здійснюється тільки після 100% оплати рахунку`,
  },
  {
    id: 4,
    title: `Грошовий переказ`,
    description: `Для того, щоб здійснити оплату замовлення через систему електронного переказу грошей, Вам необхідно 
    зв'язатися з менеджером Інтернет-магазину і уточнити всю потрібну інформацію`,
  },
  {
    id: 5,
    title: `Оплата по факту`,
    description: `Оплата здійснюється співробітнику компанії, по факту поставки товарів на указану вами адресу, за 
    попереднім узгодженням з менеджером, та при попереднім здійсненні оплати за вартість доставки в обидва кінці.`,
  },
];
